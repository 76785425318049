import piOFME from "./photos/_DSF0110-2.JPG";
import FallingLetters from "./FallingLettersView";
import React from 'react';
import { Helmet } from 'react-helmet';


export default class HomePage  extends React.Component {
    
render(){
    return(<> 
    <Helmet><title>Simon Sundins portfolio | Home</title></Helmet>  
    <FallingLetters/>
    <center><this.ImageLoaderAbout/></center>
    </>
    )
}

ImageLoaderAbout() {
    // Import result is the URL of your image
    return <img className="showCaseImage" src={piOFME} alt="Logo" />;
  }
}