import React from 'react';
import smallMe from "./photos/IMG_3554.jpeg";
import pal from "./photos/PÅL.jpeg";
import { Helmet } from 'react-helmet';



export default class About  extends React.Component {
    constructor(){
      super();
      var width = window.innerWidth >= 800 ? 400: 250;
      var height = 300;
      this.height = height;
      this.width = width; 
      var ctx = false;
      var canvas = ctx;
      var frameRate = 1/40; // Seconds
      var frameDelay = frameRate * 1000; // ms
      // eslint-disable-next-line 
      this.loopTimer = false;
      const textColour = ["yellowgreen", "violet", "steelblue", "springgreen", "seagreen", "tomato", "rosybrown", "mediumvioletred", "navy", "lightgreen", "forestgreen", "firebrick", "deeppink", "darkorange", "burlywood", "olive"];
      this.ballColour = textColour[Math.round(Math.random()*textColour.length-1)];

      var ball = [{
        index:0,
        initposition:{x: width/2, y: height/2},
        position:{x: width/2, y: height/2},
        velocity: {x: 10, y: 0},
        mass: 0.1, //kg
        radius: 15, // 1px = 1cm
        restitution: -0.7
        },
        {
          index:1,
          initposition:{x: width/2 + 5, y: height/2},
          position:{x: width/2 + 5, y: height/2},
          velocity: {x: 10, y: 0},
          mass: 0.1, //kg
          radius: 15, // 1px = 1cm
          restitution: -0.7
          },
          {
            index:2,
            initposition:{x: width/2 + 10, y: height/2},
            position:{x: width/2 + 10, y: height/2},
            velocity: {x: 10, y: 0},
            mass: 0.1, //kg
            radius: 15, // 1px = 1cm
            restitution: -0.7
          },{
              index:3,
              initposition:{x: width/2+15, y: height/2},
              position:{x: width/2+15, y: height/2},
              velocity: {x: 10, y: 0},
              mass: 0.1, //kg
              radius: 15, // 1px = 1cm
              restitution: -0.7,
              
              }];
    
    var Cd = 0.47;  // Dimensionless
    var rho = 1.22; // kg / m^3
    
    var ag = 9.81;  // m / s^2
    var mouse = {x: 0, y: 0, isDown: false};
    
    function getMousePosition(e) {
        mouse.x = e.pageX - canvas.offsetLeft;
        mouse.y = e.pageY - canvas.offsetTop;
    }

    var mouseDown = function(e,i) {
        if (e.which === 1) {
            getMousePosition(e);
            mouse.isDown = true;
            for (let i of ball){
            ball[i.index].position.x = mouse.x;
            ball[i.index].position.y = mouse.y;
            }
        }
    }
    var mouseUp = function(e,i) { 
        if (e.which === 1) {
            mouse.isDown = false;
            for (let i of ball){
            ball[i.index].velocity.y = (ball[i.index].position.y - mouse.y) /10;
            ball[i.index].velocity.x = (ball[i.index].position.x - mouse.x) / 10;
            }
        }
    }
    
    this.setup = function() {
        canvas = document.getElementById("canvas");
        ctx = canvas.getContext("2d");
        
        canvas.onmousemove = getMousePosition;
        canvas.onmousedown = mouseDown;
        canvas.onmouseup = mouseUp;
        
        ctx.fillStyle = this.ballColour;
        ctx.strokeStyle = '#000000';
        this.loopTimer = setInterval(loop, frameDelay);
    }
    var loop = function() {
        
        if ( ! mouse.isDown) {
          
          for (let i of ball){
            
            var A = Math.PI * ball[i.index].radius * ball[i.index].radius / (10000); // m^2
            // Do physics
                // Drag force: Fd = -1/2 * Cd * A * rho * v * v
            var Fx = -0.5 * Cd * A * rho * ball[i.index].velocity.x * ball[i.index].velocity.x * ball[i.index].velocity.x / Math.abs(ball[i.index].velocity.x);
            var Fy = -0.5 * Cd * A * rho * ball[i.index].velocity.y * ball[i.index].velocity.y * ball[i.index].velocity.y / Math.abs(ball[i.index].velocity.y);
            
            Fx = (isNaN(Fx) ? 0 : Fx);
            Fy = (isNaN(Fy) ? 0 : Fy);
            
                // Calculate acceleration ( F = ma )
            var ax = Fx / ball[i.index].mass;
            var ay = ag + (Fy / ball[i.index].mass);
                // Integrate to get velocity
            ball[i.index].velocity.x += ax*frameRate;
            ball[i.index].velocity.y += ay*frameRate;
            
                // Integrate to get position
            ball[i.index].position.x += ball[i.index].velocity.x*frameRate*100;
            ball[i.index].position.y += ball[i.index].velocity.y*frameRate*100;
        
        // Handle collisions
        if (ball[i.index].position.y > height - ball[i.index].radius) {
            ball[i.index].velocity.y *= ball[i.index].restitution;
            ball[i.index].position.y = height - ball[i.index].radius;
        }
        if (ball[i.index].position.x > width - ball[i.index].radius) {
            ball[i.index].velocity.x *= ball[i.index].restitution;
            ball[i.index].position.x = width - ball[i.index].radius;
        }
        if (ball[i.index].position.x < ball[i.index].radius) {
            ball[i.index].velocity.x *= ball[i.index].restitution;
            ball[i.index].position.x = ball[i.index].radius;
        }
        // Draw the ball
        
        
        ctx.clearRect(0,0,width,height);
        
        ctx.save();
        
        ctx.translate(ball[i.index].position.x, ball[i.index].position.y);
        ctx.beginPath();
        ctx.arc(0, 0, ball[i.index].radius, 0, Math.PI*2, true);
        ctx.fill();
        ctx.closePath();
        
        ctx.restore();
    
    
    
        // Draw the slingshot
        if (mouse.isDown) {
            ctx.beginPath();
            ctx.moveTo(ball[i.index].position.x, ball[i.index].position.y);
            ctx.lineTo(mouse.x, mouse.y);
            ctx.stroke();
            ctx.closePath();
        }
        
    }}}
    
          }

          componentDidMount(){
            this.setup();
          }

          componentWillUnmount(){
            this.loopTimer = false;
            clearInterval();
            
          }

    render(){
        const sitemake ={
            "maxWidth":"1000px",
        }

        const textstyle = {
            "textAlign": "left",
            "maxWidth":"500px",
        }

        const projectrow ={
            "backgroundColor": "rgb(20, 20, 20)",
            "width": "95%",
            "padding":"10px",
            "marginTop":"20px",
            "marginBottom":"20px",
            "borderRadius": "30px",
        }

        const imageColum = {
            "overflow": "hidden",
        }

        const headerProj= {
            "background": "-webkit-linear-gradient(rgb(64, 86, 161), rgb(241, 60, 32))",
            "-webkit-background-clip": "text",
            "-webkit-text-fill-color": "transparent",
            "fontSize": "40px"
        }

        const headerthing ={
            "textAlign":"left",
            "minWidth":"300px",
            "width": "95%",
        }  

        return <center>
            <Helmet><title>Simon Sundins portfolio | About</title></Helmet>
            <div className="container-fluid" style={sitemake}>
            <div style={headerthing}>
                <h3 style={headerProj}>About</h3>
                <p></p>
            </div>

                <div className="row" style={projectrow}>
                    <div className="col-lg-4" style={imageColum} >
                        <center>{this.ImageLoaderAbout(smallMe) }
                        <p>Illustration: Linda Cnattingius</p></center>
                    </div>
                    <div className="col-lg-8" >
                        <div style={textstyle}>
                            <br/><br/>
                            <h1>Hello, my name is Simon and I'm an Engineer!</h1>
                            <br/>
                            <br/>
                            Right now I am studying at KTH I am currently on my master's in the ICT program. Where I am taking courses about, interaction programming, project management, multimodal programming, and AI. I have enjoyed my studies so far and I feel ready to go on to start my career. I am looking for a web or native application programming position. 
                            <br/>
                            <br/>
                            Let's connect through  <a className="niceLinks" href="https://se.linkedin.com/in/simon-sundin-b00a5371?trk=profile-badge" target="_blank" rel="noopener noreferrer">linkedIn</a> or <a className="niceLinks"  href="mailto: simonsundinsmail@gmail.com" target="_blank" rel="noopener noreferrer">email</a>! 
                        </div>
                    </div>
                </div>
                <canvas id="canvas" style = {{"borderTopStyle": "dotted","borderRightStyle": "solid","borderBottomStyle": "solid","borderLeftStyle": "solid",}}height={this.height}  width={this.width}>Get a better browser!</canvas>
            <div className="row" style={projectrow}>
                <div className="col-lg-12" >
                    <div style={textstyle}>
                    What you just witnessed was one of my first projects including physics in a web browser. If you click the box you can flick and launch the ball in any direction (desktop only unfortunately).
                    As you can imagine there are only so many thing you can do with a <span style={{"color":this.ballColour}}>{this.ballColour}</span> ball...
                    <br/><br/>
                    During my studies at KTH I have been working in the film production business. At a production company called SwimClub, a place where I got my first taste of teamwork and what incredible effect a continuous exchange of ideas and experience can have on any project.
                    <br/><br/>
                    I love to work with new technology and do my best to be of great help to my coworkers. 
                    <br/><br/>
                    In my spare time, I love to play squash and explore photography. 
                    I have a big interest in art and if I was a billionaire I would like to create a Nobel prize for art. 
                
                    <br/>
                </div>
            </div>
        </div>
        <center>{this.ImageLoaderAbout2(pal) }
            <p style={{"width": "90%", "maxWidth": "500px", "textAlign":"left"}}>This is my cat Pål from when he locked himself inside the only place where he is not allowed...</p>
            </center>

        <br/><br/><br/>
        </div>
        </center>
            
    }

    

    ImageLoaderAbout(image, size=280) {
        const imagestyle= {
            "width": size+"px",
            "display":"block",
        }
        // Import result is the URL of your image
        return <img style = {imagestyle} src={image} alt="Logo" />;
      }

      ImageLoaderAbout2(image) {
        const imagestyle= {
            "width": "90%",
            "maxWidth": "500px",
            "display": "block" 
        }
        // Import result is the URL of your image
        return <img style = {imagestyle} src={image} alt="Logo" />;
      }

}