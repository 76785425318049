import React from 'react';
import emoji from "emoji-dictionary";

export default class fallingLetters  extends React.Component {
    constructor(props){
      super();
      this.isOn = 0;
      this.props = props;
      this.includedBoxedObjectsID = ["BoxedDIV"];
      this.collectionboxes = [];
      this.stringToMake = [..."Hello my name is Simon".split(""),];
      this.gravity = 0.3;
      this.surfaceFriction = 5;
      this.saftyfromedgeleft = 0;
      this.saftyfromedgeright = 40;
      this.startTopPosition = window.innerWidth >= 800 ?70:20;
      this.startPositionEdge = window.innerWidth >= 800 ?120:40;
      this.satesfyffromBottom = window.innerWidth >= 800 ?180:140;
      this.textsize = window.innerWidth >= 800 ?120:70;
      this.textDistance = window.innerWidth >= 800 ? 90:60;
      this.mimimumVelocity = 0.001;
      this.interval = false;
      this.textcolor = ["#D79922","#EFE2BA", "#F13C20","#4056A1", "#C5CBE3"];
      

      
      this.state = {
        isOn : 0,
        letters : this.createLetterList(this.stringToMake),
      }
      
      }
      componentDidMount(){
        setInterval(() => {
          if (this.state.isOn) {
            this.updateletterList();
          }
        }, 40);
      }

      componentWillUnmount(){
        clearInterval();
        this.interval = false;
      }

      getBoxedElements(id){
        return document.querySelector(id)
      }

      turnOnGrav(){
        if (this.state.isOn){
          this.setState({...this.state, isOn:0, letters : this.createLetterList(this.stringToMake)})
        }
        else{
        this.setState({...this.state, isOn:1});
        }
      }

      render() {
        
        return <div className="Letterbox" id="LetterSouroundingBox" onClick={() => this.turnOnGrav()}>
            { 
              this.state.letters.map((letter) => {
                return this.createLetterComponent(letter)
              })
            }
            
            <p className = "emojibutton" >{!this.state.isOn?emoji.getUnicode(":firecracker:"):emoji.getUnicode(":fire_extinguisher:")}</p>
            
        </div>

      }

      
      createLetterComponent(letterObject){
        
        const style = {
          position:"absolute",
          transform: "rotate(" +letterObject.rotation +"deg)",
          top:letterObject.position.y+"px",
          left: letterObject.position.x+"px",
          "fontSize": this.textsize + "px",
          "fontWeight": "bolder",
          "lineHeight": "80%",
          "display": "inline",
          "marginBlockEnd": "0px",
          "marginBlockStart": "0px",
          "marginInlineEnd": "0px",
          "marginInlineStart": "0px",
          "textShadow": "0 0 5px rgba(33, 33, 33, 0.2)",
          "background": "-webkit-linear-gradient("+letterObject.color1+", "+ letterObject.color2+")",
          "-webkit-background-clip": "text",
          "-webkit-text-fill-color": "transparent",
          
        };

        return <p style={style}>{letterObject.character}</p>
      }

      updateletterList(){
        //Activates the rerendering of the updated values of letters
        this.setState({...this.state , letters: this.state.letters.reduce((reducer, letter) => [...reducer, this.updatePosition(letter, reducer)], [])})
      }

      collitionChecker(letter,otherletter){
        // Just a small function that checks if you letter collided with an other letter. 
        const hitbox = this.textsize/2;
        return ((otherletter.position.y - hitbox <= letter.position.y  && letter.position.y <= otherletter.position.y + hitbox)  && ( otherletter.position.x - hitbox <= letter.position.x && letter.position.x <= otherletter.position.x + hitbox) ) ? 1 :0
      }

      updatePosition(letter, otherletters){

        const lastpositiony = letter.position.y;
        const lastpositionx = letter.position.x;
        const w = window.innerWidth;
        const h = window.innerHeight;

        //Collitioncheckers
        const collition = otherletters.reduce((reducer, otherletter) => reducer + this.collitionChecker(letter, otherletter), 0);
        const atBottom = lastpositiony >= h - this.satesfyffromBottom;
        const atLeftEdge = lastpositionx <= this.saftyfromedgeleft;
        const atRightEdge = lastpositionx >= w - this.saftyfromedgeright;

        //Checking if a minimal velocity is reached. THis is to stop the letters eventually. 
        const atMinimumVelocity = (letter.velocity.down <= this.mimimumVelocity);

        // verticalDrection 0 = down 1 = up
        // horisonalDirection 0 = left 1 = right
        const verticalDirection = atBottom || collition ? 1: (letter.velocity.down <= 0.0 && letter.velocity.verticalDirection) ? 0: letter.velocity.verticalDirection;
        const horisontalDirection =  atLeftEdge ? 0: atRightEdge ? 1 : collition && !letter.velocity.horisontalDirection ? 1: collition ? 0 : letter.velocity.horisontalDirection;

        //const willpreformrand = (!(letter.velocity.verticalDirection >=1) || - letter.velocity.verticalDirection <=1)
        const randleft_move =  (atBottom && !atMinimumVelocity)? Math.random() * this.gravity: 0;
        const surfacelost = (atBottom) ? this.surfaceFriction: 0;

        // Calculating the velocity in vertical direction. 0 is top of window 
        const down = (atBottom && atMinimumVelocity) ? 0: 
          !verticalDirection ? letter.velocity.down + this.gravity: 
          letter.velocity.down - this.gravity - randleft_move - surfacelost; 
        
        // Calculating the velocity in horisontal direction. 0 is at left of window 
        const left_hor = (atBottom && atMinimumVelocity) ? 0: 
         letter.velocity.left_hor + randleft_move
       
         
        // Calculating what the movements in horisontal or vertical direction
        const y = atBottom && atMinimumVelocity ? h - this.satesfyffromBottom : verticalDirection ? lastpositiony - down : lastpositiony + down;
        const x = atRightEdge && atMinimumVelocity ? w - this.saftyfromedgeright: horisontalDirection ? lastpositionx - left_hor: lastpositionx + left_hor;

        // The new rotation of the letters. 
        const rotation = !horisontalDirection?(angle(y, x, lastpositiony, lastpositionx)/10) + letter.rotation: letter.rotation - (angle(y, x, lastpositiony, lastpositionx)/10)

        return {...letter,
          rotation: rotation,
          position: {y: y, x:x},
          velocity: {down: down, verticalDirection: verticalDirection, left_hor:left_hor, horisontalDirection:horisontalDirection,}
        } 
      }

      createletterObject(letter="A", {top, left, down, left_hor}){
        const textColour = this.textcolor;

        let letterObj = {
          character: letter,
          rotation: 0,
          type:"p",
          color1: textColour[getRandomInt(0,textColour.length-1)],
          color2: textColour[getRandomInt(0,textColour.length-1)],
          moveable:true,
          position: {y: top, x:left},
          velocity: {down: down, verticalDirection : 1, 
                    left_hor: left_hor, horisontalDirection: Math.round(Math.random())},
        };
        return letterObj

      }


      createLetterList(letterString){
        return letterString.reduce((prevlet, letter, currentIndex)=> {

            const position = {
              // Spaces in the string will create a new line. 
              top: !currentIndex ? this.startTopPosition: prevlet[currentIndex-1].character ===" "? prevlet[currentIndex-1].position.y+this.textsize: prevlet[currentIndex-1].position.y,
              left: !currentIndex ? this.startPositionEdge: prevlet[currentIndex-1].character ===" "? this.startPositionEdge: prevlet[currentIndex-1].position.x + this.textDistance,
              down:Math.random()*10,
              left_hor:Math.random()*5,
            }
            return [...prevlet , this.createletterObject(letter, position)]}, []).reduce((r, c) => { 
              //Filtering Object that are spaces
              return c.character !== " " ? [...r , c]: [...r] 
            },[]);
      }

    }

    function getRandomInt(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
    }

    // CAlculating the rotation of the window
    function angle(cx, cy, ex, ey) {
      var dy = ey - cy;
      var dx = ex - cx;
      var theta = Math.atan2(dy, dx); // range (-PI, PI]
      theta *= 180 / Math.PI; // rads to degs, range (-180, 180]
      if (theta < 0) theta = 360 + theta; // range [0, 360)
      return theta;
    }