import React from 'react';
import { Link } from "react-router-dom";

export default class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      scrolldirection: "top",
      showmenu: true,
      data: {
        navbar: [
          {name:"Projects",link:"Projects", color: "whitesmoke", background1: "rgb(239, 226, 186)", background2:"rgb(241, 60, 32)"},
          {name:"Resumé", link:"Resume", color: "whitesmoke", background1: "rgb(215, 153, 34)", background2:"rgb(241, 60, 32)"},
          {name:"About", link:"About", color: "whitesmoke", background1: "rgb(64, 86, 161)" ,background2:"rgb(241, 60, 32)"},
           
          ]      
      },            
    };
    this.scrolldirection = "top";
    this.scrollsensativity = 20;
    this.lastKnownScrollPosition = 0;
    this.ticking = false;
  }

  componentDidMount() {
    window.addEventListener('scroll', e => this.handleNavigation(e));
}

componentWillUnmount(){
  window.removeEventListener('scroll', e => this.handleNavigation(e));
}

  handleNavigation(e){
  const sens = this.scrollsensativity;
  const oldS = this.lastKnownScrollPosition;
  const currS = (window.scrollY < 0 ? 0 : window.scrollY);
  if ((0 === currS && oldS !== 0) || currS > (oldS + sens) || (oldS - sens) > currS || (oldS === currS)){
    const scrolldirection = (oldS - currS) < 0 ? "down": currS <= 0 ? "top": (oldS === currS && currS !== 0) ? "bottom": "up";
    if (this.state.scrolldirection !== scrolldirection){
      const showmenu = scrolldirection === "down" || scrolldirection === "bottom"  ? false : true;
      this.setState({...this.state, scrolldirection: scrolldirection, showmenu:showmenu, })
    }
    this.lastKnownScrollPosition = currS;
  }
    
};




    jsUcfirst(string){
      return string.charAt(0).toUpperCase() + string.slice(1);
    }



    renderLinks(category){
      const colouration = {
        "color": category.color,
        "background": "-webkit-linear-gradient("+category.background1+"," +category.background2+")",
      }

    return <Link style= {colouration}className={!this.state.showmenu ? "headerlink collect" :  "headerlink"} id={category.name} onClick= {() => this.scrollToTop() }to={"/"+this.jsUcfirst(decodeURI(category.link)).replace(" ","")}>{category.name}</Link>
      
    }

    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }

  render() {
    
    const navbar = this.state.data.navbar;
    return <div className="header">
    <Link to="/" className="headername" >Simon Sundin</Link>
    <div className="leftlinks">
    <p className= {!this.state.showmenu ? "headerlink collect hiddenwhenbig" : "headerlink hide hiddenwhenbig"}  onClick={ () => {this.setState({...this.state, scrolldirection: "top", showmenu:true, })}}>&bull;&bull;&bull;</p>
    {navbar.map(categor => {return this.renderLinks(categor)})} 
     
    </div>
  
    </div>
    
   
  }
  

}
