import React from 'react'
import thissite from "./photos/IMG_6937.jpg";
import HEroify from "./photos/logo@300x.png";
import petrocks from "./photos/Petrocks.png";
import osqledaren from "./photos/Osqledaren1.png";
import milkyway from "./photos/MilkyWay.png";
import miltiAGENT from "./photos/AI.png";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet'

export default class Projects  extends React.Component {

    render(){

        const sitemake ={
            "maxWidth":"1000px",
        }

        const textstyle = {
            "textAlign": "left",
            "width": "95%",
            "padding":"10px",
            "maxWidth":"500px",
        }

        const projectrow ={
            "backgroundColor": "rgb(20, 20, 20)",
            "boxShadow": "0 3px 10px rgb(0 0 0 / 0.2)",
            "width": "95%",
            "marginTop":"20px",
            "marginBottom":"20px",
            "padding":"10px",
            "borderRadius": "30px",
        }

        const imageColum = {
            "overflow": "hidden",
        }

        const tecnology ={
            "fontSize": "10px"
        }

        const headerProj= {
            "background": "-webkit-linear-gradient(rgb(239, 226, 186), rgb(241, 60, 32))",
            "-webkit-background-clip": "text",
            "-webkit-text-fill-color": "transparent",
            "fontSize": "40px"
        }

        const headerthing ={
            "textAlign":"left",
            "minWidth":"300px",
            "width": "95%",
        }

        const imagecenter= {
            "position": "relative",
            "top": "50%",
            "transform": "translateY(-50%)"
        }
        
        return(
        <center>
            <Helmet><title>Simon Sundins portfolio | Projects</title></Helmet>    
        <div className="container-fluid" style={sitemake}>
            <div style={headerthing}>
            <h3 style={headerProj}>Projects</h3>
            <p>Here is a collection of fun projects I have been working on. </p>
            <br/>
            </div>



        <div className="row" style={projectrow}>
            <div className="col-lg-3" style={imageColum} >
            <div style={imagecenter}>
                {this.ImageLoaderAbout(petrocks,100)}
            </div>
            </div>
            <div className="col-lg-9" >
                <div style={textstyle}>
                    <br/>
                    <p style={headerProj}><b>PetRocks</b></p>
                    <p style={tecnology}><b>Swift ArKit SwiftUI Blender Reality Composer Core ML</b></p>
                    In the PetRock project, was investigating how haptic feedback affects the emotional connection between a human and a digital pet – specifically in the recognition and eliciting of emotions. 
                    This is particularly interesting today as many humans are experiencing loneliness as a result of social distancing due to the covid-19 pandemic. Furthermore, understanding the possibilities of haptics in a virtual environment has implications for the design of many human-computer interaction applications, ranging from fostering companionship to therapeutic interventions. 
                    <br/>
                    <br/>
                    This digital pet, dubbed “PetRock”, is available as a mobile application for iPhone 7 or later. In an attempt to create a more immersive experience, we have used three modalities that work in unison in the app: visuals in the form of augmented reality, with the purpose of mimicking the visual experience of having a pet in the same physical space as the user; auditory feedback;  and, most importantly for this project, haptics in the form of different vibrational patterns. 
                    The PetRock interacts with the human through these three modalities. The human interacts with the PetRock exclusively with touch, specifically by touching the smartphone’s touch screen. 
                    <br/>
                    <br/>
                    Petrock's was a project with a lot of different steps. 
                    I created a 3Dobject, converted it into an Apple formatted 3D asset, 
                    created an ARenviremet and MVC for state handling, updates, and view loading. 
                    Used a 3D scanning interface to create detectable objects and a hand detection library to be able to use hand gestures.
                    <br/>
                    <br/>
                    The task was to create a project that used haptics in a fun way. My partner and I decided to make a cute pet. 
                    The pet would communicate using sounds and haptics to explore the creative process of making distinguishable haptic patterns. 
                    Depicting feelings using haptics proved to be difficult.
                    But when combined with audio it can enhance the immersion. 
                    <br/>
                    <br/>
                </div>
            </div>
        </div>

        <div className="row" style={projectrow}>
            <div className="col-lg-4" style={imageColum} >
            <div style={imagecenter}>
            {this.ImageLoaderAbout(milkyway,100)}
            <iframe width = "290"  height = "200" src="https://www.youtube.com/embed/25_ChjAzGjg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            </div>
            <div className="col-lg-8" >
                <div style={textstyle}>
                    <br/>
                    <p style={headerProj}><b>Milky Way</b></p>
                    <p style={tecnology}><b>Venture creation</b> <b>Group project</b></p>
                    
                    Milky Way (MW) aims to support small- to medium-sized dairy farms in Sweden with multi-purpose animal tracking to increase their economic output while lowering costs.
                    <br/>
                    <br/>
                    Herd management and cow tracking systems are not new technology per se, yet current solutions focus on hardware sensors to track only one cow at a time and are, therefore, hard to maintain and scale. Hence, 
                    by replacing individual trackers with multi-sensor camera systems that utilise computer vision (CV), MW provides easier setup, better scalability, and multiple tracking purposes. Also, with the continuing improvements in related technologies, 
                    MW lowers maintenance costs by having fewer hardware components.
                    <br/>
                    <br/>
                    On the financial side, MW will aim to lower its early-stage costs and shorten time-to-market by outsourcing and cooperating with researchers. 
                    With MW still in its early development phase, these strategies as well as a general uptick in interest for the industry it will operate in provide advantages in securing seed funding.
                    <br/>
                    <br/>
                    MW holds a promising position to play its part in the transformation of the dairy industry, leaving the cumbersome tasks of farming to technology and letting cows get full attention and care from their holders. 
                    <br/>
                    <br/>
                </div>
            </div>
        </div>

        <div className="row" style={projectrow}>
            <div className="col-lg-3" style={imageColum} >
                <div style={imagecenter}>
                    {this.ImageLoaderAbout(miltiAGENT, 100)}
                </div>
            </div>
            <div className="col-lg-9" >
                <div style={textstyle}>
                    <br/>
                    <p style={headerProj}><b>Behavior and Strategizing between multiple AI Agents</b></p>
                    <p style={tecnology}><b>Q-learning Gama Multiagent-ML Game theory</b></p>
                    My plan for this project was to make a learning Predator agent that would, over time, become more and more effective at catching different types of prey. 
                    The learning system would be based on Q-learning algorithms. 
                    I wanted to create different challenges to the predators by introducing different types of prey to create some noise and explore the boundaries of Q-learning.
                    <br/>
                    <br/>
                    For the state change triggering, each action from the action space did modify the attribute next_state at its end, and a reflex triggered by changing the value of next_state from nil.
                    This triggered reflex will call the update_q action to modify the Q-table and learn from the new state and reward change, using the Q-learning update equation (Bellman equation).
                    <br/>
                    <br/>
                </div>
            </div>
        </div>

        <div className="row" style={projectrow}>
            <div className="col-lg-4" style={imageColum} >
           
            <div style={imagecenter}>
            <iframe width = "290"  height = "400" src="https://www.youtube.com/embed/PRZGdrdXF_U" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            </div>
            <div className="col-lg-8" >
                <div style={textstyle}>
                    <br/>
                    <p style={headerProj}><b>Spotify Album Booklet Concept</b></p>
                    <p style={tecnology}><b>Figma UX</b></p>
                    Imagine while listening to your favorite album on the subway in the morning, 
                    being able to read a little about the artist's thoughts about the album. 
                    Booklets are a way musicians have been adding additional content to an album for years. 
                    As an avid vinyl collector. I enjoy the experience of listening to an album while exploring the intricate details of the insides of a two-disk album. So I made a prototype of what that the experience could realistically look like in the Spotify app. 
                    <br/>
                    <br/>
                    From the Artists viewpoint, an album booklet could also be a great way of creating a second round of buzz and attention around your album. 
                    <br/>
                    <br/>
                </div>
            </div>
        </div>


        <div className="row" style={projectrow}>
            <div className="col-lg-3" style={imageColum} >
                <div style={imagecenter}>
                {this.ImageLoaderAbout(osqledaren, 100)}
                </div>
            </div>
            <div className="col-lg-9" >
                <div style={textstyle}>
                    <br/>
                    <p style={headerProj}><b>OSQLEDAREN</b></p>
                    <p style={tecnology}><b>Leadership Project management Financial officer Culture</b></p>
                    Osqledaren is the official student magazine at KTH. 
                    Osqledaren was one of the most challenging and fun projects I have ever been a part of. 
                    I was the Editor in chief for two years and I feel proud to have pulled it off. 
                    I contributed to the projects by trying to create opportunities for everyone to develop their skills. 
                    Create tasks that matched the person executing it to a degree, 
                    that it felt exciting to be working on (as a volunteer). 
                    Create a creative workplace culture I felt proud about and where everyone involved felt like they were critical a shareholder.
                    This while balancing a slim budget. 
                    <br/>
                    <br/>
                    We printed a magazine in 10 000 copies and distributed it to every THS member's doors. 
                    We also made a completely new website and a new graphic profile and created an efficient workflow that made it easy to create beautiful magazines quickly to enable better deadlines for the writers, illustrators, and photographers. 
                    I learned so incredibly much while doing this and I am so proud to have been a part of the wonderful team. 
                    <br/>
                </div>
            </div>
        </div>

        

        <div className="row" style={projectrow}>
            <div className="col-lg-3" style={imageColum} >
                <div style={imagecenter}>
                    {this.ImageLoaderAbout(HEroify, 100)}
                </div>
            </div>
            <div className="col-lg-9" >
                <div style={textstyle}>
                    <br/>
                    <p style={headerProj}><b>Hero-ify</b></p>
                    <p style={tecnology}><b>JavaScript React CSS</b></p>
                    Hero-ify is a web development project where my group and 
                    I decided to create a Spotify playlist generator. It creates a Spotify playlist tailored to your favorite superhero. We combined 2 APIs, 
                    a superhero API, and Spotify's API. The interaction is pretty straightforward. 
                    You choose a hero, you choose some parameters that reflect your current mood, and then a playlist is created and added to your Spotify playlists.
                    <br/>
                    <br/>
                    <Link className="niceLinks" to={"/heroify"}>Try it here...</Link>
                    <br/>
                </div>
            </div>
        </div>

        <div className="row" style={projectrow}>
            <div className="col-lg-3" style={imageColum} >
                {this.ImageLoaderAbout(thissite)}
                </div>
            <div className="col-lg-9" >
                <div style={textstyle}>
                    <br/>
                    <p style={headerProj}><b>SIMONSUNDIN.COM</b></p>
                    <p style={tecnology}><b>JavaScript React CSS</b></p>
                        A simple portfolio website. 
                        I try to use as few libraries as possible. 
                        The falling letters are something I created myself. 
                        I see this site as a place to try out some ideas or 
                        try out a new skill I would like to explore.
                        It's a Netlify deployed website, built using React.
                        <br/>
                </div>
            </div>
        </div>
        

        </div>
   
        <br/><br/><br/>
        </center>)

    }

    ImageLoaderAbout(image, size=100) {
        const imagestyle= {
            "width": size+"%",
            "display":"block",
            
        }
        // Import result is the URL of your image
        return <img style = {imagestyle} src={image} alt="Logo" />;
      }

}