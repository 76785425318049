import React from 'react';
import githubimg from '../photos/GitHub-Mark-32px.png'

class Footer extends React.Component{
    render() {
        return(<div className="footer">
            <div className="footerDiv">
            <span className="footerText1">&#xA9; Simon Sundin {this.getYear()} </span>
            <a className="footerText2" href="https://github.com/sisundin" target="_blank" rel="noopener noreferrer">
                {this.ImageLoaderAbout(githubimg, 20)}
            </a>
            </div>
            </div>)
    }

    getYear(){return new Date().getFullYear()}

    ImageLoaderAbout(image, size=280) {
        const imagestyle= {
            "width": size+"px"
           
        }
        // Import result is the URL of your image
        return <img style = {imagestyle} src={image} alt="Logo" />;
      }

}

export default Footer;
