import React from "react";
import "../App.css";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import '../App.css';
import SearchRenderPromise from "../util/searchRenderPromise.js";
import ProgressBar from "../components/HeaderAndFooter/header.js";
import HeroDisplay from "../components/createHeroDisplay.js";

export default class ChooseHero extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.searchWord = "superman";
    this.state = {
      freetext: "superman",
    };}


  update(searchphrase) {
    console.log(this.searchWord)
    this.setState({...this.state, freetext: searchphrase,
    });
  }

  fixsearchword(e){
    
    if (e.code === "Enter") {
      
      this.update(e.target.value);
  }
    else{this.searchWord = e.target.value + e.key;}
  }


  render() {
    console.log(this.state.freetext)
    console.log(this.searchWord)
    return (
      <div className="outsideDiv">
        <ProgressBar step={"1"} />
        <center>
        <p className="viewHeader">Choose your hero</p>
        <p className="copy">Choose a hero of your liking.<br/> 
        What would Spiderman, Master Chief,
        Harry Potter or any other hero out there have in their headphones?
        Or perhaps Darth Vader if you woke up on the dark side.
        </p>
        </center>
        <div>
          <div id="searchresult" className="searchresult">
          <form className="searchbox">
                <input
                  id="searchInput"
                  size="lg"
                  type="text"
                  onKeyPress={(e) =>{
                    this.fixsearchword(e);
                    }}
                  placeholder="Batman, Yoda, James Bond..."
                />
                <Button
                  className="button"
                  id="seachbutton"
                  variant="btn btn-warning"
                  onClick={() => this.update(this.searchWord)}
                >
                  Search!
                </Button>
              </form>
              <div className="divider"></div>
              <SearchRenderPromise
                promise={this.props.model.searchHero(this.state.freetext)}
                renderData={({ data }) => this.createHeroDisplay(data)}
              />
          </div>
        </div>
      </div>
    );
  }

  createHeroDisplay(hero) {
    return (
      <div>
        <HeroDisplay hero={hero} />
        <div className="divider"></div>
        <Link to="/heroify/chooseMood">
          {" "}
          <div className="text-center">
            <Button
              className="button"
              variant="btn btn-warning btn-lg"
              onClick={() => {
                this.props.model.setHero(hero);
              }}
            >
              Select {hero.name}
            </Button>
          </div>
        </Link>
        <div className="divider"></div>
        <div className="divider"></div>
      </div>
    );
  }
}
