import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Link } from "react-router-dom";
import React from 'react';
import HomePage from './components/Homepage.jsx';
import Header from './components/Header-Footer/Header.jsx';
import Footer from "./components/Header-Footer/footer"
import About from './components/About.jsx';
import Resume from './components/Resume.jsx';
import Projects from'./components/Projects.jsx';
import emoji from "emoji-dictionary";
//Heroify

import HeroIfyModel from "./components/Heroify/modelandconfig/model.js";
import SignInView from "./components/Heroify/signin/signInView.js";
import ChooseHero from "./components/Heroify/choosehero/chooseHero";
import ChooseMood from "./components/Heroify/playlistcreator/selectMood.js";
import ChooseEnergy from "./components/Heroify/playlistcreator/selectEnergy.js";
import ChooseLength from "./components/Heroify/playlistcreator/selectLength.js";
import ShowPlaylist from "./components/Heroify/playlistcreator/showPlaylist.js";




class App extends React.Component {
    constructor(props) {
      super(props);
      this.heromodel = HeroIfyModel;
      this.heromodel.addObserver("");
      this.state = {};
    }



    componentWillUnmount(){
      this.heromodel.removeObserver("");
      clearInterval();
    }


    render(){
  return (
    <BrowserRouter> 
    <Header/>
    <React.Fragment>
    <Routes>
    <Route path="/"  element={<HomePage/>}/>

    <Route path="/About"  element={<About/>}/>
    
    <Route path="/Resume"  element={<Resume/>}/> 

    <Route path="/Projects"  element={<Projects/>}/>

    <Route path="/Heroify"
                
                element={<SignInView model={this.heromodel} />}
              />
              <Route
                path="/Heroify/choosehero"
                
                element={<ChooseHero model={this.heromodel} />}
              />
              <Route
                path="/Heroify/chooseMood"
                
                element={<ChooseMood model={this.heromodel} />}
              />
              <Route
                path="/Heroify/chooseEnergy"
                
                element={<ChooseEnergy model={this.heromodel} />}
              />
              <Route
                path="/Heroify/chooseLength"
                
                element={<ChooseLength model={this.heromodel} />}
              />
              <Route
                path="/Heroify/showPlaylist"
              
                element={<ShowPlaylist model={this.heromodel} />}
              /> 

    <Route element={this.NoMatch} />
    </Routes>
    </React.Fragment>
    <Footer/>
    </BrowserRouter>
  );
}

NoMatch = () => (
  <div className="pageWrapper">
    <div className="pageTopDivider"></div>
    <div className="text-center">
      <br/>
      <h1 className="headerPageText" >404 Page Not Found <br/>{emoji.getUnicode(":construction:")} OR IS UNDER CONSTRUCTION {emoji.getUnicode(":construction:")}</h1>
      <br/>
      <Link to="/">
        <h1>Click here to go to homepage</h1>
      </Link>
    </div>
  </div>
);
}
export default App;
