import React from 'react';
import { Helmet } from 'react-helmet';


export default class Resume extends React.Component {
    

    render(){
      const sitemake ={
        "maxWidth":"1000px",
    }

    const textstyle = {
        "textAlign": "left",
        "width": "95%",
        "padding":"10px",
        "maxWidth":"500px",
    }

    const projectrow ={
        "backgroundColor": "rgb(20, 20, 20)",
        "boxShadow": "0 3px 10px rgb(0 0 0 / 0.2)",
        "width": "95%",
        "marginBottom":"20px",
        "padding":"10px",
        "marginTop":"20px",
        "borderRadius": "30px",
    }

    const imageColum = {
        "overflow": "hidden",
    }



    const headerProj= {
        "background": "-webkit-linear-gradient(rgb(215, 153, 34), rgb(241, 60, 32))",
        "-webkit-background-clip": "text",
        "-webkit-text-fill-color": "transparent",
        "fontSize": "40px"
    }

    const headerthing ={
        "textAlign":"left",
        "minWidth":"300px",
        "width": "95%",
    }

    const imagecenter= {
      "textAlign": "center",
        "position": "relative",
        "top": "50%",
        "transform": "translateY(-50%)"
    }
  
        let workplaceImage = {
            "width":"200px"
        }

        return(<center>    
          <Helmet><title>Simon Sundins portfolio | Resumé</title></Helmet>
          <div className="container-fluid" style={sitemake}>
            <div style={headerthing}>
                <h3 style={headerProj}>Resumé</h3>
                <p>Experienced Administrative Assistant with a demonstrated history of working in the higher education industry. Skilled in Javascript, React, Python (Programming Language), PHP, C++, Google Cloud Platform, Figma and jQuery. Currently studying at KTH Royal Institute of Technology.</p>
            </div>
               <h1> Experience</h1> 

               <div className="row" style={projectrow}>
            <div className="col-lg-3" style={imageColum} >
                <div style={imagecenter}>
                <img src="https://media-exp1.licdn.com/dms/image/C4D0BAQGkcfLVY-zvDA/company-logo_100_100/0/1534319667361?e=1640217600&amp;v=beta&amp;t=XCU7Aki_0RTCOLnPEhsz7D2XmIdnXIbv3VBOhJONm9E" loading="" alt="KTH Royal Institute of Technology" id="ember313" className="pv-entity__logo-img EntityPhoto-square-5 lazy-image ember-view"/>
                </div>
            </div>
            <div className="col-lg-9" >
                <div style={textstyle}>
                <span><b>KTH Royal Institute of Technology</b><br/>
                        Title: Developed independently question-based course material for a JavaScript module in a KTH course.<br/>
                        Subtext: During the summer of 2021 I developed the course literature for introductory courses to Javascript using a learning metodology called Question based learning.<br/>
                        Full-time<br/>
                            Dates Employed: Jun 2021 – Sept 2021 <br/>
                            Location: Stockholm, Stockholm County, Sweden<br/><br/>

                        Title: Course Assistant - Interaction Programming and the Dynamic Web<br/>
                        Part-time<br/>
                            Dates Employed: Sep 2020 – Present <br/>
                            Location: Stockholm, Stockholm County, Sweden<br/><br/>
                        
                        Title: Course Assistant - Intro to JavaScript<br/>
                        Part-time<br/>           
                            Dates Employed: Aug 2020 – Oct 2020<br/>            
                            Location: Stockholm Metropolitan Area<br/>
                         </span>
                </div>
                </div>
            </div>

            <div className="row" style={projectrow}>
            <div className="col-lg-3" style={imageColum} >
                <div style={imagecenter}>
                <img src="https://media-exp1.licdn.com/dms/image/C4E0BAQGcqqmM3ndLVg/company-logo_100_100/0/1519913265026?e=1640217600&amp;v=beta&amp;t=u552FVp2Ieu5yJ3LuV3-J5PfOttTRw2W8DjSdKR6uk8" loading="" alt="Osqledaren" id="ember322" className="pv-entity__logo-img EntityPhoto-square-5 lazy-image ember-view"/>
                </div>
            </div>
            <div className="col-lg-9" >
                <div style={textstyle}>
                <span><b>Editor In Chief</b><br/>
                Company Name: Osqledaren<br/>
                Full-time<br/>
                Dates Employed: Jun 2018 – Jun 2020<br/>
                Employment Duration: 2 years<br/>
                Location: Stockholm Metropolitan Area</span>
                </div>
                </div>
            </div>


            <div className="row" style={projectrow}>
            <div className="col-lg-3" style={imageColum} >
                <div style={imagecenter}>
                <img style={workplaceImage}  src="https://freight.cargo.site/t/original/i/3dfcf01c75d77b62996135928527ca2212853a7d82a5c3ce8538d450ecc49398/logo_sc.png" loading="" alt="Swimclub studios" id="ember329" className="pv-entity__logo-img EntityPhoto-square-5 lazy-image ghost-company ember-view"/>
                </div>
            </div>
            <div className="col-lg-9" >
                <div style={textstyle}>
                <span ><b>Production Leader</b><br/>
                Company Name: Swimclub studios<br/>
                Freelance<br/>
                Dates Employed Dec 2019 – Feb 2020<br/>
                Employment Duration: 3 mos<br/>
                Location Stockholm Metropolitan Area</span>
                </div>
                </div>
            </div>
  
            <div className="row" style={projectrow}>
            <div className="col-lg-3" style={imageColum} >
                <div style={imagecenter}>
                <img src="https://media-exp1.licdn.com/dms/image/C4D0BAQGkcfLVY-zvDA/company-logo_100_100/0/1534319667361?e=1640217600&amp;v=beta&amp;t=XCU7Aki_0RTCOLnPEhsz7D2XmIdnXIbv3VBOhJONm9E" loading="" alt="Royal Institute of Technology" id="ember336" className="pv-entity__logo-img EntityPhoto-square-5 lazy-image ember-view"/>
                </div>
            </div>
            <div className="col-lg-9" >
                <div style={textstyle}>
                <span ><b>Course Assistant - Programming Techniques and Matlab</b><br/>
                Company Name: Royal Institute of Technology<br/>
                Part-time<br/>
                Dates Employed: Sep 2017 – Jun 2018<br/>
                Employment Duration: 10 mos<br/>
                Location: Stockholm Metropolitan Area</span>
                </div>
                </div>
            </div>

            <div className="row" style={projectrow}>
            <div className="col-lg-3" style={imageColum} >
                <div style={imagecenter}>
                <img src="https://media-exp1.licdn.com/dms/image/C4E0BAQGxXVwsyX_wzA/company-logo_100_100/0/1519864672255?e=1640217600&amp;v=beta&amp;t=ao1v95AbQGa4j87oN6QJoM-kZRpgIvktSYJfIT4C350" loading="" alt="Chapter of Media Technology |&nbsp;KTH" id="ember343" className="pv-entity__logo-img EntityPhoto-square-5 lazy-image ember-view"/>
                </div>
            </div>
            <div className="col-lg-9" >
                <div style={textstyle}>
                <span ><b>Board Member</b><br/>
                Company Name: Chapter of Media Technology |&nbsp;KTH <br/>
                Part-time<br/>
                Dates Employed: Aug 2016 – May 2017<br/>
                Employment Duration: 10 mos<br/>
                Location: Stockholm County, Sweden</span>
                </div>
                </div>
            </div>  
        
            

        <h1> Education</h1>   

        <div className="row" style={projectrow}>
            <div className="col-lg-3" style={imageColum} >
                <div style={imagecenter}>
                <img src="https://media-exp1.licdn.com/dms/image/C4D0BAQGkcfLVY-zvDA/company-logo_100_100/0/1534319667361?e=1640217600&amp;v=beta&amp;t=XCU7Aki_0RTCOLnPEhsz7D2XmIdnXIbv3VBOhJONm9E" loading="" alt="Royal Institute of Technology" id="ember336" className="pv-entity__logo-img EntityPhoto-square-5 lazy-image ember-view"/>
                </div>
            </div>
            <div className="col-lg-9" >
                <div style={textstyle}>
                <span><b>Royal Institute of Technology</b><br/>
                Program: Computer Science and Media Technology<br/>
                Dates Studying: 2016 – 2022<br/>
                Location: Stockholm Metropolitan Area</span>
                </div>
                </div>
            </div>



        </div>
        </center>
        )



    }
}
