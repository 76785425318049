import React, { Component } from "react";
import '../App.css';
import {REACT_APP_CLIENT_ID, REACT_APP_AUTHORIZE_URL, REACT_APP_REDIRECT_URL, scopes} from "../modelandconfig/apiConfig.js"
import { Button } from "react-bootstrap";
import ProgressBar from "../components/HeaderAndFooter/header.js";
import logo from "../Assets/logo@300x.png";

//import { connect } from 'react-redux';

export default class SignInView extends Component {


  render() {
    

   const handleLogin = () => {
    window.location = `${REACT_APP_AUTHORIZE_URL}?client_id=${REACT_APP_CLIENT_ID + scopes}&redirect_uri=${REACT_APP_REDIRECT_URL}&response_type=token&show_dialog=true`;
  };


    return (
      <div className="outsideDiv">
        <ProgressBar step={"0"} />
        <div className="divider"></div>
        <div className="center">
          <img className="heroify-logo-large" src={logo} alt=""></img>
        </div>
        <div className="divider"></div>
        <p className="center copy3">
          Get a customized Spotify playlist based on the hero <br />
          you want to be and the music you love
        </p>
        <div className="divider"></div>
        <div className="text-center">
          <Button
            className="button"
            variant="btn btn-warning btn-lg"
            onClick={handleLogin}
            type="submit"
          >
            
              Let's go!
            
          </Button>
        </div>
        <div className="divider"></div>
        <div className="divider"></div>
      </div>
    );
  }
}
